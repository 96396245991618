import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { FormControl, UntypedFormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { ApplyTemplateModalComponent } from '@cwan-gpt-ui/libs/prompt-template'
import {
  ChatMessageModel,
  ChatOptions,
  ChatSession,
  ModelInfo,
  PdfAction,
  PromptTemplate,
  Tool,
  defaultModel,
} from '@cwan-gpt-ui/models'
import {
  ChatService,
  PromptTemplateService,
  SessionService,
  SharedDocumentsService,
} from '@cwan-gpt-ui/services'
import {
  RootState,
  appendMessage,
  prependSessionToList,
  selectAppliedTemplate,
  selectChatHistory,
  selectChatSession,
  selectHasDebugTools,
  selectIsBetaUser,
  selectIsDebugModeSet,
  selectIsPrivateModeSet,
  selectSelectedFiles,
  selectTemporaryFiles,
  selectUserEmail,
  selectUserId,
  setAppliedTemplate,
  setCurrentSession,
  setCurrentSessionModel,
  setCurrentSessionOptions,
  setIsDebugModeSet,
  setIsPrivateModeSet,
  setSelectedFiles,
  setTemporaryFiles,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { forEach } from 'lodash'
import { ToastrService } from 'ngx-toastr'
import { Observable, Subject } from 'rxjs'
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators'
import {
  CLIENT_ACCOUNT_USER_TOOL,
  DATA_SOURCE_DEFINITIONS_DOC_LINK,
  DATA_SOURCE_HR_SELF_SERVICE,
  DATA_SOURCE_MY_DOCUMENTS,
  DATA_SOURCE_SHARED_DOCUMENTS,
  MODEL_GPT4_TURBO,
} from './constants'

@Component({
  selector: 'cwan-gpt-chat',
  templateUrl: './cwan-gpt-chat.component.html',
  styleUrls: [`./cwan-gpt-chat.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CwanGPTChatComponent implements OnInit, OnDestroy {
  chatHistory: ChatMessageModel[] = []
  currentSession?: ChatSession
  sessions: ChatSession[] = []

  debugOption = new UntypedFormControl(false)
  debug = false
  privateMode = false

  privateModeOption = new FormControl<boolean>(false)

  sidebarVisibility: { [key: string]: boolean } = {
    sessions: false,
    tools: false,
  }
  isMyDocumentsSelected = false
  selectedFiles: string[] = []
  temporaryFiles: { [key: string]: string[] } = {}
  isSharedDocumentsSelected = false

  isClientAccountUserToolSelected = false
  isJiraProjectSelected = false

  selectedCategory?: string

  temperature = 0.9
  availableModels: ModelInfo[] = []
  _selectedModel: ModelInfo = defaultModel
  set selectedModel(value: ModelInfo) {
    const newModel: ModelInfo = {
      ...value,
      temperature: value.temperature || this.temperature,
    }

    if (!this.currentSession) {
      //empty session, just save the setting
      this._selectedModel = newModel
      return
    }

    //redux update results in this._selectedModel being updated
    this.store.dispatch(setCurrentSessionModel(newModel))
  }
  get selectedModel() {
    return this._selectedModel
  }

  toolOptions: Promise<Tool[]> = this.chatService.getAvailableTools()
  _selectedTools: string[] = []
  set selectedTools(values: string[]) {
    const options: ChatOptions = {
      tools: values,
    }

    //redux update results in this._selectedTools being updated
    this.store.dispatch(setCurrentSessionOptions(options))
  }
  get selectedTools() {
    return this._selectedTools
  }

  templateOptions$: Observable<PromptTemplate[]> | undefined
  allTemplates$: Observable<PromptTemplate[]> | undefined

  //selectedTemplate is what the user selects from the drop-down on this page
  // this is different from applied template, which is set in redux from the templates page
  selectedTemplate?: PromptTemplate

  isBetaUser$ = this.store.select(selectIsBetaUser)
  isDebugToolsAccess$ = this.store.select(selectHasDebugTools)
  dataSourceDefinitionsDocLink = DATA_SOURCE_DEFINITIONS_DOC_LINK
  private userId?: string
  private userEmail?: string
  private readonly destroyed$ = new Subject<void>()

  constructor(
    private readonly chatService: ChatService,
    private readonly sessionService: SessionService,
    private readonly store: Store<RootState>,
    private readonly templateService: PromptTemplateService,
    private readonly sharedDocumentsService: SharedDocumentsService,
    private readonly dialog: MatDialog,
    private readonly toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.templateService.fetchAllTemplates()
    this.chatService
      .getAvailableModels()
      .then((models) => (this.availableModels = models))

    this.sharedDocumentsService.currentCategory.subscribe(
      (category) => (this.selectedCategory = category)
    )

    const allTemplates$ = this.templateService.getAllTemplates$()
    this.templateOptions$ = allTemplates$?.pipe(
      map((options) => options.filter((option) => option.showInMenu)),
      map((options) => options.sort((a, b) => a.name.localeCompare(b.name)))
    )

    if (!this.isMobile()) {
      this.toggleSidebarVisibility(['sessions', 'tools'])
    }

    //This should  be the first subscription, because when this initializes, we want to be sure that if a session is active, it is set first.
    this.store
      .select(selectChatSession)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((session) => {
        this.onSessionUpdated(session)
      })

    //must come AFTER selector for session, because on startup: first we set the session, THEN we apply a template
    this.store
      .select(selectAppliedTemplate)
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((template) => {
        this.applyTemplate(template, false)
        //clear it immediately
        this.store.dispatch(setAppliedTemplate({ template: undefined }))
      })

    this.store
      .select(selectSelectedFiles)
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((files) => {
        this.selectedFiles = files
      })

    this.store
      .select(selectTemporaryFiles)
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((files) => {
        this.temporaryFiles = files
      })

    this.store
      .select(selectIsDebugModeSet)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isDebugModeSet) => {
        this.debugOption.setValue(isDebugModeSet)
        this.debug = isDebugModeSet
      })

    this.store
      .select(selectIsPrivateModeSet)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isPrivateModeSet) => {
        this.privateModeOption.setValue(isPrivateModeSet)
        this.privateMode = isPrivateModeSet
      })

    this.store
      .select(selectUserId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((userId) => {
        this.userId = userId
      })
    this.store
      .select(selectUserEmail)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((userEmail) => {
        this.userEmail = userEmail
      })

    this.store
      .select(selectChatHistory)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((chatHistory) => {
        this.chatHistory = chatHistory
      })
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  /**
   * Comes from the "New Chat Session" button
   */
  handleCreateNewSession() {
    this.startNewSession()
  }

  /**
   * Comes from when the user navigates to an existing session.
   * Since private mode does not save sessions, when a user
   * navigates to a previous, existing session, disable the
   * private mode toggle and set it to "off" so that the user
   * knows they are no longer in private mode (and cannot
   * enter it while in an existing session that is already saved).
   */
  handleNavigateToSession() {
    this.store.dispatch(
      setIsPrivateModeSet({
        isPrivateModeSet: false,
      })
    )
  }

  private async startNewSession() {
    const oldSession = this.currentSession

    //nuke any file selections
    this.store.dispatch(setSelectedFiles({ selectedFiles: [] }))

    //create and start the new session - this will reset the tools and model selections to defaults
    const newSession = this.sessionService.generateNewSession()
    this.store.dispatch(prependSessionToList(newSession))
    this.store.dispatch(setCurrentSession(newSession))

    this.enablePrivateModeToggle()

    //nuke the old one if it's empty
    if (oldSession && isEmptySession(oldSession))
      await this.sessionService.deleteSession(oldSession.id)
  }

  async deleteCurrentSession() {
    const oldSession = this.currentSession
    //always have a session, even if it's empty!
    this.startNewSession()
    if (oldSession) await this.sessionService.deleteSession(oldSession.id)
  }

  async copyChatHistory() {
    let history = ''
    for (const chat of this.chatHistory) {
      history = history + chat.role + ': ' + chat.content + '\n'
    }
    try {
      await navigator.clipboard.writeText(history)
    } catch (error) {
      console.error('Error copying to clipboard', error)
    }
  }

  async togglePrivateMode() {
    if (!this.privateMode)
      this.toastr.info(
        'Crystal will not save chats beyond the current session.',
        'You are in Private Mode',
        {
          timeOut: 5000,
        }
      )
    this.store.dispatch(
      setIsPrivateModeSet({
        isPrivateModeSet: !this.privateMode,
      })
    )
  }

  private onSessionUpdated(session: ChatSession | undefined) {
    //no session - we always want a session going, even if it's empty
    if (!session) {
      this.startNewSession()
      return
    }

    this.currentSession = session
    this.updateSelections(session)
  }

  private updateSelections(session: ChatSession) {
    //update selections to reflect session settings!
    this._selectedModel = session.model
    this.temperature = session.model.temperature || this.temperature

    //Note: Tools/options are treated differently from other session objects.
    //      The full options are constructed and sent as params to sendMessage, but only the names are stored in redux.
    const sessionTools = session.options?.tools ?? []
    this._selectedTools = sessionTools.map((t) => {
      if (typeof t == 'string') {
        return t
      }
      return t.name
    })

    const tempFiles: string[] = []
    session.history.forEach((chat) => {
      if (chat.uploadedFilename) {
        //select previously selected temporary files
        tempFiles.push(chat.uploadedFilename)
      }
    })

    if (tempFiles.length) {
      this.store.dispatch(
        setTemporaryFiles({
          filenames: tempFiles,
          sessionId: this.currentSession?.id || '',
        })
      )
    }

    this.isMyDocumentsSelected = this._selectedTools?.includes(
      DATA_SOURCE_MY_DOCUMENTS
    )
    this.isSharedDocumentsSelected = this._selectedTools?.includes(
      DATA_SOURCE_SHARED_DOCUMENTS
    )

    this.isClientAccountUserToolSelected = this._selectedTools?.includes(
      CLIENT_ACCOUNT_USER_TOOL
    )
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.isMobile()) {
      this.sidebarVisibility = { sessions: false, tools: false }
    }
  }

  private updateRequiredModelForTools(selectedTools: string[]) {
    let newModel = { ...this._selectedModel }

    if (
      selectedTools.includes(DATA_SOURCE_MY_DOCUMENTS) ||
      selectedTools.includes(CLIENT_ACCOUNT_USER_TOOL) ||
      selectedTools.includes(DATA_SOURCE_SHARED_DOCUMENTS)
    ) {
      const gpt4t = this.findAvailableModel(MODEL_GPT4_TURBO)
      if (gpt4t) newModel = gpt4t
    }

    //prevent infinite loop - only signal update if it's changed.
    if (newModel.model != this._selectedModel.model)
      this.selectedModel = { ...newModel, temperature: this.temperature }
  }

  private findAvailableModel(modelName: string): ModelInfo | undefined {
    return this.availableModels.find((m) => m.model === modelName)
  }

  async handleMessageSend(userMessage: string) {
    //Note that action dispatch and listener handling is SYNCHRONOUS (hooray!)
    //this means that we can create a session here, and then immediately send the message, and all listeners will be up-to-date on the new session.
    //https://github.com/reduxjs/redux/blob/fe0ace21910bc596cd8f3ec8ccc78bccdae7d426/src/createStore.js#L158-L216
    if (!this.currentSession) {
      console.error(
        'not current session - there should always be a current session'
      )
      return
    }

    //union types are the devil
    let tools: Tool[] | string[]
    const specialTools = this.createSpecialTools()
    if (specialTools) tools = specialTools
    else tools = this._selectedTools

    this.disablePrivateModeToggle()

    this.disablePrivateModeToggle()

    await this.chatService.sendMessage(
      userMessage,
      {
        tools: tools,
      },
      this.privateMode
    )
  }

  /**
   * Inspects tool selections and returns Tools with options set
   * Unfortunately right now each of these special tools can only be used in isolation
   */
  private createSpecialTools(): Tool[] | undefined {
    if (
      !this.selectedTools.length &&
      (checkIfMyDocuments(this.currentSession) ||
        this.temporaryFiles[this.currentSession?.id || ''])
    ) {
      return [
        {
          name: 'my_documents',
          options: {
            user_id: '' + this.userId,
            filenames: this.temporaryFiles[this.currentSession?.id || ''],
          },
        } as Tool,
      ]
    }

    if (this.selectedTools.includes(DATA_SOURCE_HR_SELF_SERVICE)) {
      return [
        {
          name: 'hr_queries',
          options: {
            email: this.userEmail,
            filenames: this.selectedFiles,
          },
        } as Tool,
      ]
    }

    if (
      this.selectedFiles &&
      this.selectedTools.includes(DATA_SOURCE_MY_DOCUMENTS)
    ) {
      return [
        {
          name: 'my_documents',
          options: {
            user_id: '' + this.userId,
            filenames: this.selectedFiles.length
              ? this.selectedFiles
              : this.temporaryFiles[this.currentSession?.id || '']
              ? this.temporaryFiles[this.currentSession?.id || '']
              : undefined,
          },
        } as Tool,
      ]
    }
    if (
      this.selectedCategory &&
      this.selectedTools.includes(DATA_SOURCE_SHARED_DOCUMENTS)
    ) {
      return [
        {
          name: 'shared_documents',
          options: {
            category: '' + this.selectedCategory,
          },
        } as Tool,
      ]
    }
    return undefined
  }

  async handlePdfAction(payload: PdfAction) {
    this.handleMessageSend(payload.messageText)
  }

  isMobile() {
    return window.innerWidth < 1028
  }

  onDataSourceUpdate($event: any) {
    const newTools = $event
    //update model (as a suggestion, it can be changed later)
    this.updateRequiredModelForTools(newTools)
    this.selectedTools = newTools
  }

  toggleSidebarVisibility(sidebars: string[]) {
    forEach(this.sidebarVisibility, (value, key: string) => {
      this.sidebarVisibility[key] = sidebars.includes(key)
        ? !this.sidebarVisibility[key]
        : this.isMobile()
        ? false
        : this.sidebarVisibility[key]
    })
  }

  private applyTemplate(
    template: PromptTemplate | undefined,
    createNewSession: boolean
  ) {
    if (!template) return

    if (createNewSession) {
      this.startNewSession()
    }

    if (template.role === 'user') {
      this.handleMessageSend(template.prompt)
    } else {
      this.store.dispatch(
        appendMessage({
          role: 'system',
          content: template.prompt,
        })
      )
    }
  }

  onDebugModeChange(event: boolean) {
    this.store.dispatch(setIsDebugModeSet({ isDebugModeSet: event }))
  }

  onTemplateChange(template: PromptTemplate | null) {
    if (template) {
      this.openApplyPromptModal()
    }
  }

  async openApplyPromptModal() {
    try {
      if (!this.selectedTemplate) return

      const dialogRef = this.dialog.open(ApplyTemplateModalComponent, {
        width: '80vw',
        maxWidth: '800px',
        minHeight: '65vh',
      })

      dialogRef.componentInstance.modalHeader = this.selectedTemplate?.name
      dialogRef.componentInstance.openedTemplate = this.selectedTemplate
      dialogRef.componentInstance.currentSession = this.currentSession
      dialogRef.componentInstance.isSystemTemplate =
        this.selectedTemplate?.role === 'system'

      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          this.selectedTemplate = undefined
          return
        }
        this.applyTemplate(result.template, result.createNewChat)
        this.selectedTemplate = undefined
      })
    } catch {
      // clear the selection from dropdown if the modal is dismissed
      this.selectedTemplate = undefined
    }
  }

  handleTemperatureChange(temperature: number) {
    const updatedModel = { ...this.selectedModel }
    updatedModel.temperature = temperature
    this.temperature = temperature
    //results in redux update
    this.selectedModel = updatedModel
  }

  enablePrivateModeToggle() {
    this.privateModeOption.enable()
  }

  disablePrivateModeToggle() {
    this.privateModeOption.disable()
  }
}

function isEmptySession(session: ChatSession) {
  const userMessages = session.history.filter((m) => m.role == 'user')
  return userMessages.length == 0
}

function checkIfMyDocuments(session?: ChatSession) {
  if (Array.isArray(session?.options?.tools)) {
    return (session?.options?.tools as Tool[])?.find(
      (tool: any) => tool?.name === DATA_SOURCE_MY_DOCUMENTS
    )
  } else {
    return
  }
}

function getSessionFiles(session: ChatSession) {
  return session?.options?.file
}
