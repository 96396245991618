import { Injectable } from '@angular/core'
import {
  AccessLevel,
  DigitalSpecialist,
  DsUser,
  LLMTool,
  ResourceData,
  Skill,
  UserInstance,
} from '@cwan-gpt-ui/models'
import {
  RootState,
  addSkill,
  addSpecialist,
  addTool,
  removeSkill,
  removeSpecialist,
  removeTool,
  selectDigitalSpecialistRecords,
  setAvailableTools,
  setDigitalSpecialists,
  setDsUsers,
  setKnowledge,
  setSelectedInstance,
  setSelectedSkill,
  setSelectedSpecialist,
  setSelectedTool,
  setSkills,
  setUpdatableDigitalSpecialists,
  setUpdatedSkill,
  setUpdatedSpecialist,
  setUpdatedTool,
  setUserInstances,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { DigitalSpecialistApi } from './digital-specialist-api.service'

@Injectable({
  providedIn: 'root',
})
export class DigitalSpecialistService {
  private specialists: Record<string, DigitalSpecialist> = {}

  constructor(
    private readonly dsApi: DigitalSpecialistApi,
    private readonly store: Store<RootState>
  ) {
    //one thing about ngrx that grinds my gears is there's no direct access into redux state - only select with synchronous callback.
    // this makes code all loopy and hard to understand.  Sometimes I just want to get the state directly!
    this.store.select(selectDigitalSpecialistRecords).subscribe((dsMap) => {
      this.specialists = dsMap
    })
  }

  //----DIGITAL SPECIALIST ACTIONS----

  async loadAllSpecialists(accessLevel = AccessLevel.READ) {
    const allDs: DigitalSpecialist[] = await this.dsApi.getAllSpecialists(
      accessLevel
    )
    this.store.dispatch(setDigitalSpecialists(allDs))
  }

  async loadAllUpdatableDigitalSpecialists(accessLevel = AccessLevel.READ) {
    const allDs: DigitalSpecialist[] = await this.dsApi.getAllSpecialists(
      accessLevel
    )
    this.store.dispatch(setUpdatableDigitalSpecialists(allDs))
  }

  /**
   * This loads the details - tool, skill, etc
   */
  async loadSpecialistDetails(username: string): Promise<DigitalSpecialist> {
    const ds: DigitalSpecialist = await this.dsApi.getSpecialist(username)
    this.store.dispatch(setUpdatedSpecialist(ds))
    return ds
  }

  /**
   * Provides direct access into the redux state
   */
  getCachedSpecialist(username: string): DigitalSpecialist | undefined {
    return this.specialists[username]
  }

  /**
   * Sets the selected digital specialists.
   * Loads it if it's not already been loaded
   * @param username
   */
  setSelectedSpecialist(username: string | undefined) {
    this.store.dispatch(setSelectedSpecialist(username))
  }

  async updateSpecialist(ds: DigitalSpecialist) {
    const updatedData = await this.dsApi.updateSpecialist(ds)

    //updates the instance in the store
    this.store.dispatch(setUpdatedSpecialist(updatedData))
  }

  async createSpecialist(ds: DigitalSpecialist) {
    const newDs = await this.dsApi.createSpecialist(ds)

    //adds the new instance to the store
    this.store.dispatch(addSpecialist(newDs))
  }

  async cloneSpecialist(username: string, newUsername: string) {
    const ds = await this.dsApi.cloneSpecialist(username, newUsername)

    //adds the new instance to the store
    this.store.dispatch(addSpecialist(ds))
  }

  async deleteSpecialist(username: string) {
    await this.dsApi.deleteSpecialist(username)
    //remove from store
    this.store.dispatch(removeSpecialist(username))
  }

  async loadKnowledge(username: string): Promise<string[]> {
    const files = await this.dsApi.getKnowledgeFiles(username)
    this.store.dispatch(setKnowledge(files))
    return files
  }

  async uploadKnowledgeFiles(username: string, files: FileList) {
    for (let i = 0; i < files.length; i++) {
      await this.dsApi.uploadKnowledgeFile(username, files[i])
    }
    this.loadKnowledge(username)
  }

  async deleteKnowledgeFiles(username: string, fileNames: string[]) {
    await this.dsApi.deleteKnowledgeFiles(username, fileNames)
    this.loadKnowledge(username)
  }

  //----TOOL ACTIONS----
  async loadAllTools() {
    const tools = await this.dsApi.getAvailableTools()
    this.store.dispatch(setAvailableTools(tools))
  }

  async loadTool(id: string): Promise<LLMTool> {
    const tool = await this.dsApi.getTool(id)
    this.store.dispatch(setUpdatedTool(tool))
    return tool
  }

  setSelectedTool(id: string | undefined) {
    this.store.dispatch(setSelectedTool(id))
  }

  async createTool(tool: LLMTool) {
    const newTool = await this.dsApi.createTool(tool)
    this.store.dispatch(addTool(newTool))
  }

  async updateTool(tool: LLMTool) {
    const updatedTool = await this.dsApi.updateTool(tool)
    this.store.dispatch(setUpdatedTool(updatedTool))
  }

  async deleteTool(toolId: string) {
    await this.dsApi.deleteTool(toolId)
    this.store.dispatch(removeTool(toolId))
    this.setSelectedTool(undefined)
  }

  //----SKILL ACTIONS----
  async loadAllSkills() {
    const skills = await this.dsApi.getSkills()
    this.store.dispatch(setSkills(skills))
  }

  async loadSkill(id: string): Promise<Skill> {
    const skill = await this.dsApi.getSkill(id)
    this.store.dispatch(setUpdatedSkill(skill))
    return skill
  }

  setSelectedSkill(id: string | undefined) {
    this.store.dispatch(setSelectedSkill(id))
  }

  async createSkill(skill: Skill) {
    const newSkill = await this.dsApi.createSkill(skill)
    this.store.dispatch(addSkill(newSkill))
  }

  async updateSkill(skill: Skill) {
    const updatedSkill = await this.dsApi.updateSkill(skill)
    this.store.dispatch(setUpdatedSkill(updatedSkill))
  }

  async deleteSkill(id: string) {
    await this.dsApi.deleteSkill(id)
    this.store.dispatch(removeSkill(id))
    this.setSelectedSkill(undefined)
  }

  // ----SESSION ACTIONS---- see digital-specialist-session.service.ts

  // ----USER INSTANCE ACTIONS----
  async loadAllUserInstances() {
    const instances: UserInstance[] = await this.dsApi.getUserInstances()
    this.store.dispatch(setUserInstances(instances))
  }

  async loadUserInstance(username: string) {
    const instance: UserInstance = await this.dsApi.getUserInstance(username)
    this.store.dispatch(setSelectedInstance(instance))
  }

  // ----DS SHARE INSTANCE ACTIONS----
  async loadDsUsers({ resourceId, sharedResouceType }: ResourceData) {
    if (!resourceId) return

    const dsUsers = await this.dsApi.getDsUsers(sharedResouceType, resourceId)
    this.store.dispatch(
      setDsUsers({ [sharedResouceType + resourceId]: dsUsers })
    )
  }

  async assignDsUsers(
    { resourceId, sharedResouceType }: ResourceData,
    data: DsUser[]
  ) {
    await this.dsApi.assignDsToUsers(sharedResouceType, resourceId, data)
  }

  async unassignDsUsers(
    { resourceId, sharedResouceType }: ResourceData,
    data: DsUser[]
  ) {
    await this.dsApi.unassignDsFromUsers(sharedResouceType, resourceId, data)
  }
}
