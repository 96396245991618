import { HttpEventType } from '@angular/common/http'
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { FileType } from '@cwan-gpt-ui/models'
import { FileUploadService } from '@cwan-gpt-ui/services'
import { ToastrService } from 'ngx-toastr'
import { v4 as uuidv4 } from 'uuid'

export interface FileEvent {
  selectedFiles: File[]
}
@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnDestroy {
  constructor(
    private readonly fileUploadService: FileUploadService,
    private toastr: ToastrService
  ) {}

  @Input() userFiles?: string[]
  @Input() title =
    'Supported documents are xlsx, docx, pdf, pptx, png, jpg, jpeg, bmp, tiff'
  @Input() extensions =
    'pdf,PDF,Pdf,xlsx,XLSX,Xlsx,docx,DOCX,Docx,pptx,PPTX,Pptx,png,Png,PNG,bmp,Bmp,BMP,jpg,Jpg,JPG,jpeg,Jpeg,JPEG,tiff,Tiff,TIFF'
  @Input() accept = '.xlsx,.docx,.pdf,.pptx,.png,.jpg,.jpeg,.bmp,.tiff'
  @Input() fileType = FileType.USER_DOCS_FILE
  selectedFiles = new UntypedFormControl()
  progress = 0

  disableButton = false
  showProgress = false
  trackProgressTimeout = 0
  timeout = 2000
  uploadStatusErrorCount = 0
  @Output() uploadCompletionEvent = new EventEmitter<{
    filename: string
    uniqueFilename: string | undefined
  }>()

  public form: UntypedFormGroup = new UntypedFormGroup({
    selectedFiles: this.selectedFiles,
  })
  resetForm() {
    this.disableButton = false
    this.progress = 0
    this.showProgress = false
    this.selectedFiles = new UntypedFormControl()
    this.form = new UntypedFormGroup({
      selectedFiles: this.selectedFiles,
    })
  }

  onChange(selectedFiles: File[]) {
    this.disableButton = false
    const fileName = selectedFiles[0].name.replace(/[|+]/g, '_')
    const fileExists = this.userFiles?.includes(fileName)
    if (
      fileExists &&
      !confirm('File already exists. Do you want to replace existing file ?')
    ) {
      this.selectedFiles = new UntypedFormControl()
    }
  }

  async trackUploadProgress(jobId: string, selectedFile: File) {
    try {
      const status = await this.fileUploadService.getUploadStatus(jobId)
      this.uploadStatusErrorCount = 0
      if (status.stage === 'ERROR') {
        this.resetForm()
        this.toastr.error('File Upload Failed, please retry again.')
        await this.fileUploadService.deleteFile(selectedFile.name)
      } else if (status.percent_complete === 100) {
        this.completeFileUploadProgress(selectedFile)
      } else {
        if (this.progress < 79) {
          this.progress = this.progress + 9
        }
        this.trackProgressTimeout = setTimeout(() => {
          this.timeout = Math.min(7000, this.timeout * 1.25)
          this.trackUploadProgress(jobId, selectedFile)
        }, this.timeout)
      }
    } catch (error) {
      console.error(error)
      if (++this.uploadStatusErrorCount < 4) {
        this.trackProgressTimeout = setTimeout(() => {
          this.trackUploadProgress(jobId, selectedFile)
        }, 2000 * this.uploadStatusErrorCount)
      } else {
        this.resetForm()
        this.toastr.error('File Upload Failed, please retry again.')
        await this.fileUploadService.deleteFile(selectedFile.name)
      }
    }
  }

  private changeFileExtensionToLowercase(filename: string) {
    const parts = filename.split('.')
    const extension = parts.pop()?.toLowerCase()
    return `${parts.join('.')}.${extension}`
  }

  private completeFileUploadProgress(
    selectedFile: File,
    filename: string | undefined = undefined
  ) {
    this.progress = 95
    setTimeout(() => {
      this.progress = 100
    }, 1000)
    setTimeout(() => {
      this.resetForm()
    }, 2000)
    this.toastr.success('File Uploaded successfully')
    this.disableButton = false
    this.uploadCompletionEvent.emit({
      filename: selectedFile.name,
      uniqueFilename: filename,
    })
  }

  async submit($event: FileEvent) {
    this.progress = 0
    this.disableButton = true
    this.showProgress = true
    const selectedFile = $event.selectedFiles[0]
    const fileBlob = new Blob(
      [new Uint8Array(await selectedFile.arrayBuffer())],
      { type: selectedFile.type }
    )
    const filename =
      this.fileType == FileType.API_SPEC_FILE
        ? `${uuidv4()}_${selectedFile.name}`
        : selectedFile.name
    const formData = new FormData()
    formData.append(
      'file',
      fileBlob,
      this.changeFileExtensionToLowercase(filename)
    )
    this.fileUploadService
      .uploadFile(formData, undefined, this.fileType)
      .then((event) => {
        const jobId = event?.jobId
        if (jobId) this.trackUploadProgress(jobId, selectedFile)
        else {
          if (event.type === HttpEventType.UploadProgress) {
            // update progress bar
            this.progress = event.total
              ? Math.round((100 * event.loaded) / event.total)
              : 0
          } else {
            // upload complete, handle response
            this.completeFileUploadProgress(selectedFile, filename)
          }
        }
      })
      .catch((error) => {
        if (error) {
          this.resetForm()
          this.toastr.error('File Upload Failed, please retry again.')
        }
      })
  }

  ngOnDestroy(): void {
    clearTimeout(this.trackProgressTimeout)
  }
}
