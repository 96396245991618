import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import {
  RootState,
  setClientId,
  setTenantId,
  setUserEmail,
  setUserId,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { environment } from 'environments/environment'
import {
  emulateSidenavAndLogin,
  emulateSidenavWithoutLogin,
} from './sidenav-emulator'

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly store: Store<RootState>
  ) {}

  /**
   * If the app is not integrated with sidenav, this handles the login (with CW Auth0 Config only),
   *   and sets up the window.sidenav object
   *
   * @returns after auth0 login completes
   */
  async loginWithEmulatedSidenav() {
    await emulateSidenavAndLogin()
    this.setUserInfo()
  }

  /**
   * If the app is not integrated with sidenav, this handles the auth0 token but does not login
   *   and sets up the window.sidenav object
   *
   * @returns after auth0 login completes
   */
  async emulateSidenavWithoutLogin(
    cwAuth0ConfigClientId?: string,
    cwAuth0ConfigOrganization?: string
  ): Promise<void> {
    if (!cwAuth0ConfigClientId || !cwAuth0ConfigOrganization)
      throw new Error(
        'cwAuth0ConfigClientId or cwAuth0ConfigOrganization is undefined'
      )
    await emulateSidenavWithoutLogin(
      cwAuth0ConfigClientId,
      cwAuth0ConfigOrganization
    )
    this.setUserInfo()
  }

  /**
   * This will load the real sidenav and wait for login to complete.
   * It expects the sidenav-app and script
   *
   * @returns after auth0 login completes
   */
  async loadSidenavAndLogin(useCwanConfig = true) {
    this.loadSidenav(this._document, useCwanConfig)
    await this.waitForSidenavLogin()
    this.setUserInfo()
  }

  /**
   * If the app already loads sidenav, this will simply wait for the login to complete
   *
   * @returns after auth0 login completes
   */
  async waitForSidenavLogin() {
    const promise = new Promise<void>((resolve, reject) => {
      let sidenavInitCounter = 0
      //Because of potential race conditions, the boolean value should be checked
      //  before waiting for the event.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any)?.sidenav?.isAuthenticationComplete) resolve()
      else {
        window.addEventListener('cwanAuthCompleted', () => {
          resolve()
        })

        const interval = setInterval(() => {
          if (sidenavInitCounter++ > 600) {
            clearInterval(interval)
            reject(new Error('Timed out waiting for sidenav init'))
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((window as any)?.sidenav?.isAuthenticationComplete) {
            clearInterval(interval)
            resolve()
          }
        }, 100) //check every 100ms
      }
    })
    promise.then(() => {
      this.setUserInfo()
    })
    return promise
  }

  /**
   * Load the Clearwater Console sidenav, which handles navigation to other CW apps as well as logout.
   * This is not exactly a webcomponent, but behaves very similarly due to dynamically-loaded js
   * index.html has script tag placeholders, which we find by ID, and then set the source for the appropriate environment
   * When the script loads it finds sidenavApp div, gets its configuration, and draws itself there.
   *
   * @param document - the DOM document, injected rather than pulled via Angular just to keep this code vanilla
   */
  private loadSidenav(document: Document, useCwanConfig: boolean) {
    const now = Date.now()
    const baseUrl = environment.sidenavBaseUrl

    const sidenavAppEl = document.getElementById('sidenavApp')
    if (!sidenavAppEl) {
      throw Error('Cannot find sidenavApp')
    }

    /**
     * For cwan-gpt, it should NEVER be used by a client, so we hard-code the cwan auth0Config
     * Otherwise, for cwic-studio, we let sidenav find the auth0Config in the cookie
     */
    if (useCwanConfig) {
      sidenavAppEl.setAttribute('auth0-domain', environment.auth0.domain)
      sidenavAppEl.setAttribute('auth0-client-id', environment.auth0.clientId)
      sidenavAppEl.setAttribute(
        'auth0-organization',
        environment.auth0.organization
      )
      sidenavAppEl.setAttribute('auth0-audience', environment.auth0.audience)
    }

    //now load the sidenav script
    const sidenavSrcEl = document.createElement('script')
    sidenavSrcEl?.setAttribute('src', `${baseUrl}/sidenavbar.js?v=${now}`)
    document.body.appendChild(sidenavSrcEl)
  }

  private setUserInfo() {
    const userId = this.getIdentifier()
    this.store.dispatch(setUserId(userId))

    const email = (window as any)?.sidenav?.user?.email
    const clientId: string =
      (window as any)?.sidenav?.user?.clearwaterData?.clientId + ''
    const tenantId: string = (window as any)?.sidenav?.user[
      'http://clearwateranalytics.com/user'
    ]?.tenantId
    this.store.dispatch(setUserEmail(email))
    this.store.dispatch(setClientId(clientId))
    this.store.dispatch(setTenantId(tenantId))
  }

  getIdentifier() {
    const userId = (window as any)?.sidenav?.user?.clearwaterData?.legacyUserId
    if (userId) return String(userId)

    const auth0Id = (window as any)?.sidenav?.user?.auth0Id as string
    return sanitizeUserId(auth0Id)
  }
}

export function sanitizeUserId(userId: string): string {
  return userId.replace(/[?&|/\\,%]/g, '-')
}
