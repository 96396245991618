import { CiamUser } from './cwan-gpt-api.model'

export interface DigitalSpecialist {
  avatar?: string
  fullName: string
  username: string
  modelInfo?: DigitalSpecialistModel
  privateBio: string
  publicBio: string
  active?: boolean
  jobTitle: string
  jobDescription: string
  tools: LLMTool[]
  skills: Skill[]
  welcomeMessage?: string
  suggestedQuestions: string[]
  promptTemplates: string[]
  useMemories?: boolean
  tenantId: string
  userPermissions?: UserPermissions
  privateModeDefault?: boolean
  termsOfService?: DigitalSpecialistTermsOfService
}

export interface DigitalSpecialistAvailableTool {
  key: string
  name: string
  description: string
}

export interface LLMTool {
  toolId: string
  name: string
  description: string
  usageDescription: string
  isPlaintext: true
  type: string
  samplePrompts?: string[]
  tenantId: string
  properties: {
    apiTool?: {
      url: string
      requestMethod?: string
      requestBody?: string
      requestQueryParams?: { [key: string]: string }
      requestHeaders?: { [key: string]: string }
    }
    temperature?: string
    model?: string
    systemPrompt?: string
    specType?: string
    specUrl?: string
    filename?: string
    s3Filename?: string
    specText?: string
    apiGuidelines?: string
    filenameAsInput?: boolean
    filenameAsOutput?: boolean
    returnDirect?: boolean
    script?: string
  }
  userPermissions?: UserPermissions
}

export interface DigitalSpecialistModel {
  model: string
  temperature?: number
}

export interface Skill {
  skillId: string
  name: string
  description: string
  instructions: string
  returnDirect?: boolean
  tools: LLMTool[]
  modelInfo?: SkillModel
  tenantId: string
  userPermissions?: UserPermissions
}

export interface SkillModel {
  model: string
  temperature?: number
}

export interface Step {
  toolId: string
  stepDescription: string
}

export interface UserInstance {
  user_id: number
  username: string
  userInstanceId: string
  creationDatetime?: string
  lastAccessedDatetime?: string
}

export interface DigitalSpecialistSession {
  sessionId: string
  userId: string
  specialistUsername: string
  creationDatetime?: string //format is ISO, e.g. "2023-12-21T20:25:58.788459+00:00"
  firstLineText: string
  summaryText: string
}

export interface ToolType {
  name: string
  description: string
  icon: string
  id: string
}

export interface Memory {
  memoryId: string
  memoryType: string
  content: string
  sessionId: string
  instanceId: string
  sender: string
}

export interface UserPermissions {
  canCreate: boolean
  canDelete: boolean
  canRead: boolean
  canUpdate: boolean
  hasGlobalAdmin: boolean
  hasInternalAdmin: boolean
}

export interface DigitalSpecialistTermsOfService {
  effectiveDate: Date
}

export enum AccessLevel {
  READ = 'READ',
  UPDATE = 'UPDATE',
}

export interface DsUser extends CiamUser {
  role: string
}

export interface ResourceData {
  resourceName: string
  resourceId: string
  sharedResouceType: SharedResourceType
}

export enum SharedResourceType {
  SPECIALISTS = 'specialists',
  SKILLS = 'skills',
  TOOLS = 'tools',
}
